import React, { Component } from 'react';
import Gloves from '../Components/Gloves'

class GlovesPage extends Component {
    render() {
        return (
            <div>
                <Gloves />
            </div>
        );
    }
}

export default GlovesPage;