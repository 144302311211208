import React, { Component } from 'react';
import ScarvesPage from '../Pages/ScravesPage';
import ScarvesPricePage from '../Pages/ScarvesPricePage';
import Gloves from '../Pages/GlovesPage';
import GlovesPricePage from '../Pages/GlovesPricePage';
import Bags from '../Pages/BagsPage';
import BagsPricePage from '../Pages/BagsPricePage';
import Home from '../Pages/Home';
import Parfum from './Parfum';
import BeltsPricePage from './BeltsPrice';


// import Main from './Main'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

class Top extends Component {
    render() {
        return (
           
            <Router>
                <Navbar collapseOnSelect expand="lg" className=" text-center ">
                    <Container  >

                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto mlink m-auto ">
                                <Nav.Link eventKey='1' class="m-auto px-4 my-3 " as={Link} to="/">Home</Nav.Link>
                                <Nav.Link eventKey='2' class="m-auto px-4 my-3 " as={Link} to="/ScarvesPage">Scarves</Nav.Link>
                                <Nav.Link eventKey='2' class="m-auto px-4 my-3 " as={Link} to="/ScarvesPricePage">Scarves Price</Nav.Link>
                                <Nav.Link eventKey='3' class="m-auto px-4 my-3 " as={Link} to="/Gloves">Gloves</Nav.Link>
                                <Nav.Link eventKey='3' class="m-auto px-4 my-3 " as={Link} to="/GlovesPricepage">Gloves Price</Nav.Link>
                                <Nav.Link eventKey='4' class="m-auto px-4 my-3 " as={Link} to="/Bags">Bags</Nav.Link>
                                <Nav.Link eventKey='4' class="m-auto px-4 my-3 " as={Link} to="/BagsPricePage">Bags Price</Nav.Link>
                                <Nav.Link eventKey='4' class="m-auto px-4 my-3 " as={Link} to="/BeltsPricePage">Belts Price</Nav.Link>
                                <Nav.Link eventKey='5' class="m-auto px-4 my-3 " as={Link} to="/Parfum">Parfum</Nav.Link>
                                {/* <Nav.Link eventKey='4' class="mp m-auto px-4 my-3 " as={Link} to="/Proiectare">Servicii de Proiectare</Nav.Link>
                                <Nav.Link eventKey='5' class="mp m-auto px-4 my-3 " as={Link} to="/Echipament">Echipament sportiv</Nav.Link> */}

                                {/* <Nav.Link eventKey='6' class="mp m-auto px-4 my-3" as={Link} to="/Contacte">Contacte</Nav.Link> */}
                            </Nav>
                        </Navbar.Collapse>

                    </Container>
                </Navbar>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/ScarvesPage' element={<ScarvesPage />} />
                    <Route path='/ScarvesPricePage' element={<ScarvesPricePage />} />
                    <Route path='/Gloves' element={<Gloves />} />
                    <Route path='/GlovesPricePage' element={<GlovesPricePage />} />
                    <Route path='/Bags' element={<Bags />} />
                    <Route path='/BagsPricePage' element={<BagsPricePage />} />
                    <Route path='/BeltsPricePage' element={<BeltsPricePage />} />
                    <Route path='/Parfum' element={<Parfum />} />
                    {/* <Route path='/Echipament' element={<Echipament />} /> */}
                    
                </Routes>
            </Router>

        

        );
    }
}

export default Top;