import React, { Component } from 'react';
import ScarvesPrice from '../Components/ScarvesPrice';

class ScarvesPricePage extends Component {
    render() {
        return (
            <div>
                <ScarvesPrice />
            </div>
        );
    }
}

export default ScarvesPricePage;