import React, { Component } from 'react';
import BagsPrice from '../Components/BagsPrice';

class BagsPricePage extends Component {
    render() {
        return (
            <div>
               <BagsPrice /> 
            </div>
        );
    }
}

export default BagsPricePage;