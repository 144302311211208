import React, { Component } from 'react';
import Scarves from '../Components/Scarves'


class ScravesPage extends Component {
    render() {
        return (
            <div>
                
              
                <Scarves />
            </div>
        );
    }
}

export default ScravesPage