
import React, { Component, useRef } from 'react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { useState } from 'react';
import { read, utils } from 'xlsx';
const imQr = 'https://michelkatana.ru/uploads/_attach/product/generator/qr.jpg'

let arrayHeader = []
let arrayValues = []
let dim = []
let xlsxImportName = 'Michel_Katana_Bags'
let ramas = ''
let opt = ''
let rrt = ''

function tdPrint(data, imKey1, art) {
    const imKey2 = imKey1 !== null ? imKey1.replace('-1.jpg', '-2.jpg') : null
    const im1 = 'https://michelkatana.ru/uploads/_attach/product/generator/' + imKey1
    const im2 = 'https://michelkatana.ru/uploads/_attach/product/generator/' + imKey2
    let i = -1
    const lengthX = data.length
    return (
        data.map(x => {

            i++
            if (i === 1) { return (<td style={{ border: "1px solid #000", width: '10px', textAlign: "center", fontSize: "7pt", verticalAlign: "middle" }}>{x}</td>) }
            if (i === 2) { return (<td style={{ border: "1px solid #000", width: '10px', textAlign: "center", fontSize: "9pt", verticalAlign: "middle" }}>{x}</td>) }
            if (i === 3) { return (<td style={{ border: "1px solid #000", width: '10px', textAlign: "center", fontSize: "9pt", verticalAlign: "middle" }}>{x}</td>) }
            if (i === 4) { return (<td style={{ border: "1px solid #000", width: '10px', textAlign: "center", fontSize: "9pt", verticalAlign: "middle" }}>{x}</td>) }
            if (i === 5) { return (<td style={{ border: "1px solid #000", width: '10px', textAlign: "center", fontSize: "9pt", verticalAlign: "middle" }}>{x}</td>) }
            if (i === 6) { return (<td style={{ border: "1px solid #000", width: '10px', textAlign: "center", fontSize: "9pt", verticalAlign: "middle" }}>{x}</td>) }
            if (i === 7) { return (<td style={{ border: "1px solid #000", width: '132px', alignContent: "center", verticalAlign: "middle", margin: "1px 1px 0px 0px" }}><img src={im1} /> </td>) }
            if (i === 8) { return (<td style={{ border: "1px solid #000", width: '132px', alignContent: "center", verticalAlign: "middle", margin: "1px 1px 0px 0px" }}><img src={im2} /> </td>) }
            if (i === 9) { return (<td style={{ border: "1px solid #000", width: '10px', textAlign: "left", fontSize: "9pt", verticalAlign: "middle", whiteSpace: "nowrap" }}>{x}</td>) }
            if (i === 10) { return (<td style={{ border: "1px solid #000", width: '10px', textAlign: "center", color: "#003AB3", fontSize: "9pt", fontWeight: 'Bold', verticalAlign: "middle", whiteSpace: "nowrap" }}>{x}</td>) }
            if (i === 11) { return (<td style={{ border: "1px solid #000", width: '10px', textAlign: "center", fontSize: "9pt", verticalAlign: "middle", whiteSpace: "nowrap" }}>{x}</td>) }
            if (i === 12) { return (<td style={{ border: "1px solid #000", width: '10px', textAlign: "center", fontSize: "9pt", verticalAlign: "middle", whiteSpace: "nowrap" }}>{x}</td>) }
            if (i === 13) { return (<td style={{ border: "1px solid #000", width: '10px', textAlign: "center", fontSize: "9pt", verticalAlign: "middle", whiteSpace: "nowrap" }}>{x}</td>) }
            if (i === 14) { return (<td style={{ border: "1px solid #000", fontSize: '10pt', width: '95px', alignContent: "center", verticalAlign: "middle", margin: "1px 1px 0px 0px" }}><a href={x}>{art}</a> </td>) }
        })
    )

}

function XlsLoad() {
    const [data, setdata] = useState([])
    const readfile = ($event) => {
        const files = $event.target.files;
        if (files.length) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;
                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                    arrayHeader.push(Object.keys(rows[0]))
                    ramas = 'Ramas'
                 //   opt = arrayHeader[0][28]
                 //   rrt = arrayHeader[0][25]
                    arrayValues = rows
                    setdata(rows);
                }
            }
            reader.readAsArrayBuffer(file);
        }
    }
    const tableRef = useRef(null);
    {
        const inStockOnly = document.getElementById('inStockOnly') !== null ? document.getElementById('inStockOnly').checked : null
        dim.push('Наименование', 'Верх', 'Подкладка', 'Цвет', 'Особенности', 'Бренд', 'Картинка 1', 'Картинка 2', 'Артикул', 'Оптовая цена', 'Розничная цена', 'Цена на сайте', 'Размер', 'Ссылка на сайт')
        const header = dim !== undefined ? Array.from(new Set(dim)) : null
        let newArray = []

        for (let key in arrayValues) { // строки 1,2,3...
            let new1 = []
            new1[0] = arrayValues[key].Категория
            new1[1] = arrayValues[key]['Наименование краткое']
            new1[2] = arrayValues[key]['Верх']
            new1[3] = arrayValues[key]['Подкладка']
            new1[4] = arrayValues[key]['Цвет']
            new1[5] = arrayValues[key]['Особенности__']
            new1[6] = arrayValues[key]['Торговая марка']
            new1[7] = 'photo1'
            new1[8] = 'photo2'
            new1[9] = arrayValues[key].Артикул
            //   new1[10] = Math.round(parseInt (arrayValues[key]['ОПТ. Цена руб']))

            new1[10] = Math.round(arrayValues[key]['ОПТ']) // ОПТ
            new1[11] = Math.round(arrayValues[key]['РРЦ']) // РРЦ
            new1[12] = Math.round(arrayValues[key]['РРЦ сайт']) // РРЦ сайт 

            //new1[10] = arrayValues[key][opt]
            //new1[11] = arrayValues[key][rrt]
            //new1[12] = Math.round(arrayValues[key]['РРЦ - загрузка на НАШ САЙТ'])
            new1[13] = arrayValues[key]['Размер']

            const articul = arrayValues[key].Артикул !== undefined ? arrayValues[key].Артикул.replace('/', '_') : ''
            new1[14] = 'https://michelkatana.ru/search?query=' + articul

            if (inStockOnly && arrayValues[key][ramas] !== 0 && arrayValues[key].Артикул !== 'Douvines') { newArray.push(new1) } else { !inStockOnly && newArray.push(new1) }
        }
        // for (let key in newArray) {
        //   console.log(newArray[key])
        // }


        let i = -1
        return (
            <>
                <h1> С У М К И </h1>
                <fieldset class='p-1 border'>
                    <b>Шаг 1. Выберите Опции: </b>
                    <input class='mx-2' type='checkbox' id='inStockOnly' name='Только с остатками' defaultChecked />Только с остатками;
                    <input class='mx-2' type='checkbox' id='showQuantity' name='Выводить количество' />Выводить количество.
                </fieldset>
                <fieldset class='p-1 border'> <b>Шаг 2. Выберите файл Базы Эксель</b><input class='mx-2' type='file' onChange={readfile} accept='.xlsx' /></fieldset>

                <DownloadTableExcel
                    filename={xlsxImportName}
                    sheet="Catalog"
                    currentTableRef={tableRef.current}
                >
                    <fieldset class='p-1 border'><b>Шаг 3. Экспорт в Экслель </b><button class='mx-2'> Export </button></fieldset>
                </DownloadTableExcel>

                <table ref={tableRef} style={{ margin: '20px auto' }}>
                    <thead>
                        <tr style={{ fontSize: '16pt' }}>
                            <td colSpan={10} style={{ height: '162px' }}><img src={imQr} /></td>
                            <td colSpan={6} style={{ paddingLeft: '20px', fontSize: '16pt', textAlign: 'left' }}>
                                <div style={{ marginTop: '20px', fontSize: '16pt', verticalAlign: "bottom" }}><a href='mailto:info@michelkatana.ru'>info@michelkatana.ru</a></div>
                                <div style={{ marginTop: '20px', fontSize: '16pt', verticalAlign: "middle" }}><a href='https://michelkatana.ru'>michelkatana.ru</a></div>
                                <div style={{ marginTop: '20px', fontWeight: 'bold', verticalAlign: "top" }}>+7 903 544 48 42</div>
                            </td>
                        </tr>
                        <tr style={{ fontWeight: 'Bold', wordWrap: 'break-word', textAlign: "center", fontSize: "9pt", verticalAlign: "middle" }}>
                            {header.map(item => {
                                i++
                                if (i === 0) { return (<td style={{ border: "1px solid #000", backgroundColor: "#FFFF99", width: '55px' }}>{item}</td>) }
                                if (i === 1) { return (<td style={{ border: "1px solid #000", backgroundColor: "#FFFF99", width: '100px' }}>{item}</td>) }
                                if (i === 2) { return (<td style={{ border: "1px solid #000", backgroundColor: "#FFFF99", width: '100px' }}>{item}</td>) }
                                if (i === 3) { return (<td style={{ border: "1px solid #000", backgroundColor: "#FFFF99", width: '55px' }}>{item}</td>) }
                                if (i === 4) { return (<td style={{ border: "1px solid #000", backgroundColor: "#FFFF99", width: '110px' }}>{item}</td>) }
                                if (i === 5) { return (<td style={{ border: "1px solid #000", backgroundColor: "#FFFF99", width: '75px' }}>{item}</td>) }
                                if (i === 6) { return (<td style={{ border: "1px solid #000", backgroundColor: "#FFFF99", width: '122px' }}>{item}</td>) }
                                if (i === 7) { return (<td style={{ border: "1px solid #000", backgroundColor: "#FFFF99", width: '122px' }}>{item}</td>) }
                                if (i === 8) { return (<td style={{ border: "1px solid #000", backgroundColor: "#FFFF99", width: '180px' }}>{item}</td>) }
                                if (i === 9) { return (<td style={{ border: "1px solid #000", backgroundColor: "#FFFF99", width: '60px', color: '#003AB3' }}>{item}</td>) }
                                if (i === 10) { return (<td style={{ border: "1px solid #000", backgroundColor: "#FFFF99", width: '60px' }}>{item}</td>) }
                                if (i === 11) { return (<td style={{ border: "1px solid #000", backgroundColor: "#FFFF99", width: '60px' }}>{item}</td>) }
                                if (i === 12) { return (<td style={{ border: "1px solid #000", backgroundColor: "#FFFF99", width: '90px' }}>{item}</td>) }
                                if (i === 13) { return (<td style={{ border: "1px solid #000", backgroundColor: "#FFFF99", width: '80px', color: '#003AB3' }}>{item}</td>) }
                            })}
                        </tr>
                    </thead>
                    <tbody>

                        {newArray.map(x => {
                            const imKey1 = x[9] !== undefined ? x[9].replace('/', '_') + '-1.jpg' : null
                            if (x[1] === undefined && x[0] !== undefined) {
                                if (x[0] !== 'Общий итог') {
                                    return (<tr><td colSpan={5} style={{ border: "1px solid #000", height: '20px', fontSize: '11pt', fontWeight: 'bold', whiteSpace: "nowrap" }}>{x[0].replace(' Итог', '')}</td></tr>)
                                }
                            } else if (x[8] !== undefined && !x[8].includes('Итог'))
                                return (
                                    <tr style={{ height: '132px' }}>{tdPrint(x, imKey1, x[9])}</tr>
                                )
                        })}
                    </tbody>
                </table>
            </>
        )
    }
}

class Bags_old extends Component {
    render() {
        return (
            <>

                <XlsLoad />

            </>
        )
    }
}

export default Bags_old;